import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function PointSaleSolutionsPage() {
  return (
    <Layout pageName="technology">
      <SEO
        title="Point of Sales Technology and Systems"
        description="Your revenue is as quick as a queue in your store. Get flawless Point-of-sale systems and solutions from WYN Technologies. We provide custom POS setup to businesses of all sizes."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Point-of-Sale Solutions</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Point-of-Sale Solutions</GroceryTitle>
              <p>We all use POS systems every day in one form or another. From checking out at the grocery store to ordering items online, a Point of Sale system (POS) is where you'll check out your merchandise. Simply put, POS systems give businesses the ability to process payments and keep track of sales.</p>

              <p>In the days before digital technology came into the picture, Point of Sale systems were nothing more than the registers that rang up your order. We've come a long way since then, and POS systems are now entirely digital. These advances in technology give businesses the ability to check out customers regardless of location with wireless devices.</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">What We Provide with Our POS Setup & Installation Services</GroceryIndustryTitle>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/placholder.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Sales, Installation, & Product Orientation</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p><strong>WYN</strong> is happy to serve as a preferred provider of the best POS system products from leading makers. But we do much more than simply sell point of sale systems. We also help to design the perfect custom POS system setup for your particular needs, and we’ll get it all installed for you too. Our clients can verify the quality of our installation workmanship services! And after it’s all installed, we’re not done yet. We’ll give you proper product orientation and make sure you understand how everything works. We’ll be patient to answer all of your questions, too!</p>
                   {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/placholder.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Site Surveys</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Before <strong>WYN</strong> ever recommends a particular POS system for your small business, we’ll want to learn more about your business goals & needs, and we’ll take time to listen. We’ll also have one or more of our expert technicians come out and do an in-person, physical survey of your business and its layout (or intended layout). After our walkthrough, we’ll be ready to share our insights about how to best set up retail point of sale systems for your facility. We can then give better recommendations about the best POS solutions to meet both your needs and your budget.</p>
                   {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/placholder.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Free Product Demos </GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Perhaps you’re already familiar with common point of sale systems for small businesses. Or maybe you’ve seen some, but have never really had the opportunity to test them out for yourself. Regardless of where you fall on that spectrum, WYN can help you find exactly what you need! Want to learn more about the particular features and benefits of the top POS products on the market today? We’d be glad to let you experience a product demo for yourself. We’ll show you what these systems can do, and we’ll show you how to take full advantage, too. We’re here to help!</p>
                 {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/placholder.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Ongoing Support</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Unless this is your first business technology purchase, you may have had the unpleasant experience of being “ghosted” by a vendor or installer whenever you need to ask a question down the road. You won’t find that to be the case with <strong>WYN Technologies</strong>! We’re pleased to offer service-after-the-sale contracts for our clients to help keep your equipment running smoothly. And beyond that, we’ll never ghost you! If you’re experiencing a problem or issue, just give us a buzz; we’ll be glad to respond in a timely fashion. In short, we’ll always be here for you when you need us.</p>
                  {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              {/* <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle> */}
              <MarkdownContent>
                  <h2>POS Systems and How They Work</h2>
                  <p>Current retail POS Systems function the way they always have, but with a few extra features and capabilities. The customer chooses to buy a particular product - whether in person or online, and an associate or web-based application rings up the merchandise. The information is then sent to the POS in the form of a barcode or UPC, which calculates price, taxes, and inventory counts and provides a total. At this point, the customer can pay for the product, produce an analog or digital receipt, and finalize the sale.</p>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Why are POS Systems Useful?</h2>
                  <p>Retail POS systems have been in operation since the very first product was sold to a customer. Before the technological age, a Point of Sale was a role filled by a human that took your money, divvied out change, and handed you your merchandise. So, it's not hard to see just how vital these systems are to society.</p>

                  <p>And current technology gives businesses the power to use POS systems in ways previously unimaginable. These systems can instantly calculate taxes, fees, discounts, and totals while simultaneously networking with your inventory system to note the removal of the product.</p>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Mobile POS Systems</h2>
                <p>Modern retail POS hardware has moved into the mobile realm, with the ability to operate numerous devices wirelessly to conduct business. These devices have made a big splash with smaller companies that need the functionality without investing in expensive retail infrastructure.</p>

                <p>One of the most famous mobile POS systems is the Square Reader. The Square POS system rose to prominence over the last decade, and though the hardware has undoubtedly improved since its conception, it's still the most well-known of wireless retail point of sale systems.</p>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>System Features</h2>
                  <p>There are many different options when it comes to choosing the right POS system for your business, and many of these will largely depend on which features you'll require. Your type of business, sales numbers, and the number of employees you have are significant concerns when determining what fits your needs.</p>
                 
                <p>Businesses preferring a simpler method to conduct transactions may choose to use mobile POS devices, but larger companies that concurrently operate online and brick and mortar facilities may favor larger onsite or cloud POS software.</p>
                
                <p>There are also security features your business may need and what type of data you’ll want to collect from each sale. These features in modern POS software can be configured to suit just about any business need.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>POS Solutions with WYN Technologies</h2>
                <p>Traversing the complexities of Point of Sale systems can be complex for the unexperienced, but WYN Technologies can help you cut through the uncertainty and choose which retail POS system is best for your business! Our experts are devoted to providing the best POS solutions at reasonable prices. Whether you're looking for a system to handle smaller, mobile-based transactions or more complex point of sale infrastructure for larger establishments and online retailers, WYN Technologies is here to help! Give us a call today at <a href="tel:3368990555">(336) 899-0555</a> !</p>

              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default PointSaleSolutionsPage
